.Attractions {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;

    @media(min-width: 1024px) {
        margin-top: 140px;
    }

    &__title {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 25px;
        line-height: 40px;
        text-transform: uppercase;
        font-weight: 700;

        @media(min-width: 1024px) {
            position: static;
            margin: 0 80px;
        }
    }

    &__items {
        margin: 120px 0 40px;

        @media(min-width: 1024px) {
            margin: 100px 0 40px;
        }
    }

    &__item {
        margin-bottom: 60px;

        @media(min-width: 1024px) {
            display: flex;
            padding: 0 80px;
        }
    }

    &__icon {
        @media(min-width: 1024px) {
            flex-basis: 25%;
            position: relative;
        }

        i {
            text-align: center;
            display: block;
            font-size: 40px;
            margin-bottom: 20px;

            @media(min-width: 1024px) {
                text-align: center;
                position: absolute;
                left: 50%;
            }
        }
    }

    &__itemTxt {
        padding: 0 25px;

        @media(min-width: 1024px) {
            flex-basis: 75%;
        }

        h2 {
            text-transform: uppercase;
            color: #E82828;
            font-weight: 400;
            text-align: center;
            margin-bottom: 20px;

            @media(min-width: 1024px) {
                text-align: left;
            }
        }

        li {
            margin: 8px 10px;
        }
    }
}