.Gallery {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;

    &__title {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 25px;
        line-height: 40px;
        text-transform: uppercase;
        font-weight: 700;
    }

    &__imgs {
        margin-top: 80px;
    }

    &__img {
        img {
            width: 100vw;
            height: 200px;
            object-fit: cover;
            max-width: 1024px;

            @media(min-width: 1024px) {
                margin-top: 20px;
            }

            @media(min-width: 1024px) {
                height: 570px;
            }
        }
    }
}