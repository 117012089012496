* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

html {
    scroll-behavior: smooth;
}

.App {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
}