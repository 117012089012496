.Menu {
    width: 100%;
    height: 100%;
    background-color: #4B4B4B;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 2;
    transition: .2s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;

    @media(min-width: 1024px) {
        width: 100vw;
        height: 100px;
        background-color: #4B4B4B;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 2;
        color: white;
    }

    &--active {
        right: 0;
    }

    &__list {
        @media(min-width: 1024px) {
            position: absolute;
            right: 80px;
        }

        ul {
            list-style: none;
            margin-bottom: 0;

            li {
                text-align: center;
                line-height: 2em;
                text-transform: uppercase;

                @media(min-width: 1024px) {
                    display: inline-block;
                    padding-right: 1em;
                }

                a {
                    color: white;
                    text-decoration: none;
                    transition: .2s;
                    font-size: 1.2em;

                    @media(min-width: 1024px) {
                        &:hover {
                            color: rgb(189, 189, 189);
                        }
                    }
                }
            }
        }
    }
}