.Contact {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;

    @media(min-width: 1024px) {
        margin-top: 180px;
    }

    &__title {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 25px;
        line-height: 40px;
        text-transform: uppercase;
        font-weight: 700;

        @media(min-width: 1024px) {
            top: 120px;
            left: 50%;
            transform: translate(-50%);
            font-size: 1.8em;
        }
    }

    &__txt {
        margin: 100px 15px 40px;
        line-height: 1.3;

        @media(min-width: 1024px) {
            text-align: center;
            margin: 120px 15px 40px;
        }
    }

    &__part {
        margin-bottom: 15px;

        a {
            color: black;
            text-decoration: none;
        }
    }

    &__img {
        img {
            width: 100vw;
            max-width: 1024px;
        }
    }
}

.bold {
    font-weight: 700;
}