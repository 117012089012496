.Hamburger {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 3;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba($color: #000000, $alpha: .45);

    @media(min-width: 1024px) {
        display: none;
    }

    .fas {
        color: white;
        font-size: 1.4em;
        line-height: 40px;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
    }
}