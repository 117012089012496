.Offer {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;

    @media(min-width: 1024px) {
        margin-top: 140px;
    }

    &__title {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 25px;
        line-height: 40px;
        text-transform: uppercase;
        font-weight: 700;

        @media(min-width: 1024px) {
            position: static;
            margin: 0 80px;
        }
    }

    &__txt {
        margin: 80px 15px 40px;
        line-height: 1.3;

        @media(min-width: 1024px) {
            margin: 40px 80px 40px;
        }

        p {
            margin-bottom: 40px;
        }

        ul {
            margin-bottom: 40px;

            li {
                margin: 0 20px;
            }
        }
    }

    &__listTitle {
        font-weight: 700;
        margin-bottom: 10px;
        font-size: 1em;
    }
}